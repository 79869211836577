import tile_fenice from "./assets/tiles/fenice510.png";
import tile_limonta from "./assets/tiles/limonta.png";
import tile_newclassic from "./assets/tiles/newclassic.png";
import tile_pugi from "./assets/tiles/pugi.png";
import tile_urbanshadow from "./assets/tiles/urbanshadow.png";

const materiali = [
  {
    collection: "old",
    items: [
      {
        slug: "fenice 510",
        name: "fenice 510",
        thumbnail: tile_fenice,
      },
      {
        slug: "limonta pepe plus 115",
        name: "limonta pepe plus 115",
        thumbnail: tile_limonta,
      },
      {
        slug: "new classic 856 t16",
        name: "new classic 856 t16",
        thumbnail: tile_newclassic,
      },
      {
        slug: "pugi 205",
        name: "pugi 205",
        thumbnail: tile_pugi,
      },
      {
        slug: "urban shades 166",
        name: "urban shades 166",
        thumbnail: tile_urbanshadow,
      },
    ],
    collection: "line",
    items: [
      {
        name: "T89 Convert Mel 4000",
        slug: "T89 Convert Mel 4000",
        thumbnail: "#808080", // grey
      },
      {
        name: " T87  Convert Mel 4000 ",
        slug: " T87  Convert Mel 4000 ",
        thumbnail: "#FFD700", // yellow
      },
      {
        name: "T84  Convert Mel 4000",
        slug: "T84  Convert Mel 4000",
        thumbnail: "#D2B48C", // light brown
      },
      {
        name: "T85  Convert Mel  4000",
        slug: "T85  Convert Mel  4000",
        thumbnail: "#DEB887", // lighter brown
      },
      {
        name: "T88  Convert Mel  4000 ",
        slug: "T88  Convert Mel  4000 ",
        thumbnail: "#A9A9A9", // lighter grey
      },
      {
        name: "T83  Convert Mel 4000 ",
        slug: "T83  Convert Mel 4000 ",
        thumbnail: "#008080", // teal
      },
      {
        name: " T102  Convert Mel 4000 ",
        slug: " T102  Convert Mel 4000 ",
        thumbnail: "#90EE90", // light green
      },
      {
        name: "T86  Convert Mel 4000",
        slug: "T86  Convert Mel 4000",
        thumbnail: "#006400", // darker green
      },
    ],
  },
];

export default materiali;
