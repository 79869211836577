import tile_marrone from "../assets/tiles/marrone_ocra.png";
import tile_giallo from "../assets/tiles/giallo_curry.png";
import tile_blu from "../assets/tiles/blu_zaffiro.png";
import tile_mdf from "../assets/tiles/mdf_antracite.png";
import tile_honey from "../assets/tiles/honey_yellow.png";

import placeholder from "../assets/placeholder.jpg";
const struttura = [
  {
    category: "cat. A",
    collection: "old",
    items: [
      {
        slug: "marrone ocra ral 8001",
        name: "marrone ocra ral 8001",
        thumbnail: tile_marrone,
      },
      {
        slug: "giallo curry ral 1027",
        name: "giallo curry ral 1027",
        thumbnail: tile_giallo,
      },
      {
        slug: "blu zaffiro ral 5003",
        name: "blu zaffiro ral 5003",
        thumbnail: tile_blu,
      },
      {
        slug: "mdf antracite ez2",
        name: "mdf antracite ez2",
        thumbnail: tile_mdf,
      },
      {
        slug: "honey yellow ral 1005",
        name: "honey yellow ral 1005",
        thumbnail: tile_honey,
      },
    ],
  },
  {
    category: "cat. A",
    collection: "line",
    items: [
      {
        name: " V1  S711 (RAL 9010)  Pure white",
        slug: " V1  S711 (RAL 9010)  Pure white",
        thumbnail: "#FFFFFF",
      },
      {
        name: " V50  S868 (RAL8001)  Ochre brown",
        slug: " V50  S868 (RAL8001)  Ochre brown",
        thumbnail: "#955F20",
      },
      {
        name: " V38  S823 (RAL 9004)  Signal Black - Matt",
        slug: " V38  S823 (RAL 9004)  Signal Black - Matt",
        thumbnail: "#2C2C2C",
      },
      {
        name: " V47  S292 (RAL 3012)  Beige red",
        slug: " V47  S292 (RAL 3012)  Beige red",
        thumbnail: "#C1876B",
      },
      {
        name: " V65  (RAL 2002)  Vermilion",
        slug: " V65  (RAL 2002)  Vermilion",
        thumbnail: "#C63927",
      },
      {
        name: " V46  S859 (RAL 8004)  Copper brown",
        slug: " V46  S859 (RAL 8004)  Copper brown",
        thumbnail: "#8F4E35",
      },
      {
        name: "V68  S181 (RAL 6011)  Reseda green",
        slug: "V68  S181 (RAL 6011)  Reseda green",
        thumbnail: "#587246",
      },
      {
        name: " V8  6013 (RAL 6013)  Reed green",
        slug: " V8  6013 (RAL 6013)  Reed green",
        thumbnail: "#797957",
      },
      {
        name: "V69  S164 (RAL 7001)  Silver grey",
        slug: " V69  S164 (RAL 7001)  Silver grey",
        thumbnail: "#8A9597",
      },
      {
        name: "V70  S185 (RAL 6034)  Pastel turquoise",
        slug: "V70  S185 (RAL 6034)  Pastel turquoise",
        thumbnail: "#7FB5B5",
      },
      {
        name: " ML1  White",
        slug: "ML1  White",
        thumbnail: "#F5F5F5",
      },
      {
        name: " ML3  Ochre brown",
        slug: " ML3  Ochre brown",
        thumbnail: "#CD7F32",
      },
      {
        name: " ML2  Signal Black - Matt",
        slug: " ML2  Signal Black - Matt",
        thumbnail: "#2B2B2B",
      },
      {
        name: " MWL1  Natural oak",
        slug: " MWL1  Natural oak",
        thumbnail: "#DAB88B",
      },
      {
        name: " ML5  S292 (RAL 3012)  Beige red",
        slug: " ML5  S292 (RAL 3012)  Beige red",
        thumbnail: "#C1876B",
      },
      {
        name: " ML4  (RAL 2002)  Vermilion",
        slug: " ML4  (RAL 2002)  Vermilion",
        thumbnail: "#C63927",
      },
      {
        name: " ML10  S859 (RAL 8004)  Copper brown",
        slug: " ML10  S859 (RAL 8004)  Copper brown",
        thumbnail: "#8F4E35",
      },
      {
        name: " ML7  (RAL 6011)  Reseda green",
        slug: " ML7  (RAL 6011)  Reseda green",
        thumbnail: "#587246",
      },
      {
        name: " ML6  6013 (RAL 6013)  Reed green",
        slug: " ML6  6013 (RAL 6013)  Reed green",
        thumbnail: "#797957",
      },
      {
        name: " ML9  (RAL 7001)  Silver grey",
        slug: " ML9  (RAL 7001)  Silver grey",
        thumbnail: "#8A9597",
      },
      {
        name: " ML8  (RAL 6034)  Pastel turquoise",
        slug: " ML8  (RAL 6034)  Pastel turquoise",
        thumbnail: "#7FB5B5",
      },
    ],
  },
];
export default struttura;
