import React, { useCallback, useEffect, useRef, useState } from "react";
import icon_camera from "./assets/camera.svg";
import icon_plus from "./assets/plus.svg";
import icon_minus from "./assets/minus.svg";
import icon_autoRotate from "./assets/autoRotate.svg";
import icon_arrows_in from "./assets/arrowsIn.svg";
import icon_back from "./assets/arrowCounterClockwise.svg";

function Player({ productName, selectedProductCode }) {
  const emersyaIframeRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [viewerActive, setViewerActive] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://cdn.emersya.com/f/emersyaLoader.js";
    script.id = "emersya";

    script.setAttribute("routing", selectedProductCode);
    script.setAttribute("containerId", "emersyaIframe");

    document.head.appendChild(script);

    function initializeEmersyaAPI() {
      const emersyaViewer = emViewers["emersyaIframe"];
    }

    document.addEventListener(
      "emersyaViewerInitialized",
      initializeEmersyaAPI,
      false
    );

    return () => {
      document.removeEventListener(
        "emersyaViewerInitialized",
        initializeEmersyaAPI
      );
    };
  }, [selectedProductCode]);

  const zoomIn = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];

    // Consider adding a promise-based approach like in the Emersya docs:
    emersyaViewer.startZoomIn().then(() => {
      setTimeout(() => {
        emersyaViewer.stopZoomIn();
      }, 800);
    });
  }, [viewerActive]);

  const topView = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];

    // Consider adding a promise-based approach like in the Emersya docs:
    emersyaViewer.setCamera({
      camera: {
        position: [0, 4.5, 0],
        target: [0, 0.46, 0],
        up: [0, 1, 0],
        fov: 35,
        transitionTime: 500,
      },
    });
  }, [viewerActive]);

  const frontView = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];

    // Consider adding a promise-based approach like in the Emersya docs:
    emersyaViewer.setCamera({
      camera: {
        position: [0, 0.9, 3.5],
        target: [0, 0.9, 0],
        up: [0, 1, 0],
        fov: 35,
        transitionTime: 500,
      },
    });
  }, [viewerActive]);

  const zoomOut = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];
    emersyaViewer.startZoomOut();
    setTimeout(() => {
      emersyaViewer.stopZoomOut();
    }, 600);
  }, []);

  const playRotation = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];
    emersyaViewer.play();
  }, []);

  // Reset Camera
  const resetCamera = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];
    emersyaViewer.resetCamera({});
    emersyaViewer.play();
  }, []);

  // Fullscreen Functions
  const openFullscreen = useCallback(() => {
    const element = document.querySelector(".emersya_block"); // Adjust if needed
    const arrowOut = document.querySelector(".arrow_out"); // Adjust if needed
    const arrowIn = document.querySelector(".arrow_in"); // Adjust if needed

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE11 */
      element.msRequestFullscreen();
    }

    arrowOut.classList.add("hidden");
    arrowIn.classList.remove("hidden");
    setIsFullscreen(true);
  }, []);

  const closeFullscreen = useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }

    // ... update arrow visibility ...
    setIsFullscreen(false);
  }, []);

  // Screenshot Functionality
  function downloadBase64File(contentBase64, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = contentBase64;
    downloadLink.target = "_self";
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink); // Cleanup
  }

  const getScreenshot = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    emersyaViewer.getCamera().then((cameraData) => {
      emersyaViewer
        .getScreenshot({
          width: 1024,
          height: 1024,
          transparentBackground: false,
          camera: cameraData,
        })
        .then((imageData) => {
          downloadBase64File(imageData, `${formattedDate}-${productName}.png`);
        });
    });
  }, [productName]); // Add productName dependency

  const getCameraData = useCallback(() => {
    const emersyaViewer = emViewers["emersyaIframe"];

    emersyaViewer.getCamera().then((camera) => {
      const formattedData = {
        position: Array.from(camera.position),
        target: Array.from(camera.target),
        fov: camera.fov,
        zoom: camera.zoomProportion,
      };

      const output = JSON.stringify(formattedData, null, 2);

      // Create custom alert dialog
      const dialog = document.createElement("div");
      dialog.style.position = "fixed";
      dialog.style.top = "0";
      dialog.style.left = "0";
      dialog.style.width = "100%";
      dialog.style.height = "100%";
      dialog.style.backgroundColor = "rgba(0,0,0,0.5)";
      dialog.style.zIndex = "1000";
      dialog.style.display = "flex";
      dialog.style.alignItems = "center";
      dialog.style.justifyContent = "center";

      const content = document.createElement("div");
      content.style.background = "white";
      content.style.padding = "20px";
      content.style.borderRadius = "8px";
      content.style.maxWidth = "500px";
      content.style.width = "90%";

      const pre = document.createElement("pre");
      pre.style.marginBottom = "15px";
      pre.style.whiteSpace = "pre-wrap";
      pre.textContent = output;

      const copyButton = document.createElement("button");
      copyButton.textContent = "Copy Data";
      copyButton.style.padding = "8px 16px";
      copyButton.style.background = "#007bff";
      copyButton.style.color = "white";
      copyButton.style.border = "none";
      copyButton.style.borderRadius = "4px";
      copyButton.style.cursor = "pointer";
      copyButton.style.marginRight = "10px";
      copyButton.onclick = () => {
        navigator.clipboard.writeText(output).then(() => {
          copyButton.textContent = "Copied!";
          setTimeout(() => {
            copyButton.textContent = "Copy Data";
          }, 2000);
        });
      };

      const closeButton = document.createElement("button");
      closeButton.textContent = "Close";
      closeButton.style.padding = "8px 16px";
      closeButton.style.background = "#6c757d";
      closeButton.style.color = "white";
      closeButton.style.border = "none";
      closeButton.style.borderRadius = "4px";
      closeButton.style.cursor = "pointer";
      closeButton.onclick = () => dialog.remove();

      content.appendChild(pre);
      content.appendChild(copyButton);
      content.appendChild(closeButton);
      dialog.appendChild(content);
      document.body.appendChild(dialog);
    });
  }, [viewerActive]);
  return (
    <div className="ay-col-span-4 md:ay-col-span-3 md:ay-sticky ay-top-0 ay-h-fit ay-relative">
      <div className="ay-z-10 ay-absolute ay-left-5 ay-bottom-5 ay-text-md ay-flex ay-flex-col ay-gap-1">
        {/* <img onClick={playRotation} className="hover:ay-cursor-pointer" src={icon_autoRotate} alt=""/> */}
        <p
          onClick={topView}
          className="hover:ay-cursor-pointer ay-text-center ay-leading-[10px] ay-text-[8px]"
        >
          TOP <br /> VIEW
        </p>
        <p
          onClick={frontView}
          className="hover:ay-cursor-pointer ay-mt-2 ay-text-center ay-leading-[10px] ay-text-[8px]"
        >
          FRONT <br /> VIEW
        </p>
        <p
          onClick={getCameraData}
          className="hover:ay-cursor-pointer ay-mt-2 ay-text-center ay-leading-[10px] ay-text-[8px]"
        >
          CAMERA <br /> DATA
        </p>
        {/* <img onClick={resetCamera} className="hover:ay-cursor-pointer" src={icon_back} alt=""/> */}
        <img
          onClick={playRotation}
          className="hover:ay-cursor-pointer ay-w-8"
          src={icon_autoRotate}
          alt=""
        />
        <img
          onClick={zoomIn}
          className="hover:ay-cursor-pointer ay-w-8"
          src={icon_plus}
          alt=""
        />
        <img
          onClick={zoomOut}
          className="hover:ay-cursor-pointer ay-w-8"
          src={icon_minus}
          alt=""
        />
        <img
          onClick={resetCamera}
          className="hover:ay-cursor-pointer ay-w-8"
          src={icon_back}
          alt=""
        />
        <img
          onClick={getScreenshot}
          className="hover:ay-cursor-pointer ay-w-8"
          src={icon_camera}
          alt=""
        />
      </div>
      <div
        className="ay-h-[60vh] md:ay-h-screen ay-w-full ay-relative"
        id="emersyaIframe"
      ></div>
    </div>
  );
}

export default Player;
