import React, { useState, useMemo } from "react";
import { switchConfiguration } from "./config";
import placeholder from "./assets/placeholder.jpg";

function getUniqueCategories(materials) {
  return [...new Set(materials.flatMap((material) => material.category))];
}

// Utility function to check if a string is a valid hex color
const isHexColor = (str) => {
  return (
    str && typeof str === "string" && str.startsWith("#") && str.length === 7
  );
};

// Component to render either an image or a color circle
const ThumbnailDisplay = ({ thumbnail, name, className = "" }) => {
  if (isHexColor(thumbnail)) {
    return (
      <div
        className={`${className} ay-rounded-full`}
        style={{
          backgroundColor: thumbnail,
          width: "100%",
          height: "100%",
          aspectRatio: "1/1",
        }}
      />
    );
  }

  return (
    <img
      className={`${className} ay-aspect-square ay-w-full ay-rounded-full ay-object-cover`}
      src={thumbnail || placeholder}
      alt={name}
    />
  );
};

function Accordion({
  title,
  materials,
  selected,
  onChange,
  customConfig,
  defaultRivestimento,
  type,
  resetMaterial,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(
    materials.length > 0 ? materials[0].category : null
  );
  const [openCollections, setOpenCollections] = useState({});
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  // Rest of the state management code remains the same...
  const filteredMaterials = useMemo(() => {
    let filtered = materials;
    if (customConfig?.customCollection) {
      filtered = materials.filter(
        (material) => material.collection === customConfig.customCollection
      );
    }
    return filtered.filter((material) =>
      selectedCategory ? material.category === selectedCategory : true
    );
  }, [materials, customConfig, selectedCategory]);

  const searchResults = useMemo(() => {
    if (!filterText.trim()) return [];
    let searchable = materials;
    if (customConfig?.customCollection) {
      searchable = materials.filter(
        (material) => material.collection === customConfig.customCollection
      );
    }
    return searchable
      .flatMap((material) => material.items)
      .filter((item) =>
        item.name.toLowerCase().includes(filterText.toLowerCase())
      );
  }, [filterText, materials, customConfig]);

  // Handlers remain the same...
  const handleMaterialChange = (newMaterial) => {
    if (newMaterial) {
      switchConfiguration(newMaterial, type);
      setSelectedMaterial(newMaterial);
      onChange(newMaterial);
    }
  };

  React.useEffect(() => {
    if (resetMaterial) {
      setSelectedMaterial(defaultRivestimento);
      resetMaterial();
    }
  }, [resetMaterial, defaultRivestimento]);

  const handleCategoryChange = (newCategory) => {
    setSelectedCategory(newCategory);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCollectionToggle = (collection) => {
    setOpenCollections((prevState) => ({
      ...prevState,
      [collection]: !prevState[collection],
    }));
  };

  return (
    <div className="ay-w-full ay-pt-4 ay-pb-6 hover:ay-cursor-pointer ay-border-b ay-border-b-gray-300">
      <div
        className="ay-group ay-gap-x-4 ay-flex ay-justify-between ay-items-center"
        onClick={handleToggle}
      >
        <div className="ay-flex-1 ay-truncate">
          <h2 className="ay-text-lg">{title}</h2>
          <h5 className="ay-font-bold ay-text-lg ay-mt-1 ay-truncate">
            {selected?.slug || "seleziona"}
          </h5>
        </div>
        <div className="ay-w-16 ay-h-16 ay-flex-shrink-0 ay-overflow-hidden ay-rounded-full">
          <ThumbnailDisplay
            thumbnail={selected?.thumbnail}
            name={selected?.name}
            className="group-hover:ay-border ay-border-black ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
          />
        </div>
      </div>

      {isOpen && (
        <div>
          {type === "imbottito" && !customConfig?.customCollection && (
            <>
              <div className="ay-mt-4">
                <select
                  value={selectedCategory}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                  className="ay-border ay-rounded-full ay-boder-black ay-w-full ay-p-4"
                >
                  <option value="">TUTTE LE CATEGORIE</option>
                  {getUniqueCategories(filteredMaterials).map((category) => (
                    <option key={category} value={category}>
                      {category.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
              <input
                type="text"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                placeholder={`Cerca ${title}...`}
                className="ay-my-4 ay-p-4 ay-border ay-rounded-full ay-w-full"
              />
            </>
          )}

          {searchResults.length > 0 && (
            <div className="ay-search-results-popup">
              <div className="ay-category-items-grid ay-grid xl:ay-grid-cols-3 2xl:ay-grid-cols-3 lg:ay-grid-cols-2 md:ay-grid-cols-2 ay-grid-cols-3 ay-gap-4 ay-mt-2.5">
                {searchResults.map((result) => (
                  <div
                    key={result.slug}
                    className="ay-flex ay-flex-col ay-items-center ay-cursor-pointer"
                    onClick={() => handleMaterialChange(result)}
                  >
                    <div className="ay-overflow-hidden ay-rounded-full">
                      <ThumbnailDisplay
                        thumbnail={result.thumbnail}
                        name={result.name}
                        className="group-hover:ay-border ay-border-black ay-scale-125 hover:ay-scale-200 ay-transition-transform"
                      />
                    </div>
                    <p className="ay-mt-2 ay-text-center">{result.name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="ay-grid ay-grid-cols-2 ay-gap-4 ay-mt-4">
            {filteredMaterials.map((material) => (
              <CollectionItem
                key={`${material.category}-${material.collection}`}
                material={material}
                isSelected={selected === material}
                onToggle={handleCollectionToggle}
                isOpen={openCollections[material.collection]}
                onMaterialChange={handleMaterialChange}
                customConfig={customConfig}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function CollectionItem({
  material,
  isSelected,
  onToggle,
  isOpen,
  onMaterialChange,
  customConfig,
}) {
  return (
    <div
      className={`${
        customConfig ? "ay-col-span-2" : "ay-col-span-2"
      } ay-flex ay-flex-col`}
    >
      <div>
        <div className="ay-category-items-grid ay-grid ay-grid-cols-3 lg:ay-grid-cols-4 ay-gap-4 ay-mt-2.5">
          {material.items.map((item) => (
            <div
              key={item.slug}
              className={`ay-material-item ay-group ay-relative ${
                isSelected === item ? "ay-active" : ""
              }`}
              onClick={() => onMaterialChange(item)}
            >
              <div className="ay-overflow-hidden ay-rounded-full hover:ay-border-gray-300 hover:ay-border-2">
                <ThumbnailDisplay
                  thumbnail={item.thumbnail}
                  name={item.name}
                  className="ay-group-hover:ay-border ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
                />
              </div>
              <p className="ay-text-sm ay-mt-3.5">{item.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export { Accordion };
