import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";

function FamilySelector({
  products,
  selectedFamily,
  onFamilyChange,
  onProductChange,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentFamily, setCurrentFamily] = useState(selectedFamily || "");
  const dropdownRef = useRef(null);

  const families = Array.from(
    new Set(products.map((product) => product.family || "").filter(Boolean))
  );

  const handleFamilyChange = (selectedFamilyName) => {
    const familyProducts =
      selectedFamilyName === ""
        ? products
        : products.filter((product) => product.family === selectedFamilyName);

    setCurrentFamily(selectedFamilyName);
    onFamilyChange(selectedFamilyName);
    setIsOpen(false);

    // If there's only one product in the selected family, call onProductChange with its code
    if (familyProducts.length === 1) {
      onProductChange(familyProducts[0].code);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="ay-w-full ay-pt-4 ay-pb-6 ay-border-b ay-border-b-black">
      <div className="ay-group ay-gap-x-4 ay-flex ay-justify-between ay-items-center">
        <h2 className="ay-text-lg ay-font-bold">Famiglia</h2>
        {currentFamily && <p className="ay-uppercase">{currentFamily}</p>}
      </div>

      <div className="ay-mt-2 ay-relative" ref={dropdownRef}>
        <div
          className="ay-border  ay-bg-white ay-border-gray-200 ay-w-full ay-p-4 ay-flex ay-justify-between ay-items-center ay-cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{currentFamily || "TUTTI PRODOTTI"}</span>
          <span
            className={`ay-transition-transform ay-duration-300 ${
              isOpen ? "ay-rotate-180" : ""
            }`}
          >
            <FaChevronDown />
          </span>
        </div>

        {isOpen && (
          <div className="ay-absolute ay-z-10 ay-w-full ay-mt-1 ay-divide-y-2 ay-bg-white ay-border ay-border-gray-200 ay-shadow-lg ay-max-h-[70vh] ay-overflow-auto">
            <div
              className="ay-p-4 ay-hover:bg-gray-100 ay-cursor-pointer"
              onClick={() => handleFamilyChange("")}
            >
              TUTTI PRODOTTI
            </div>
            {families.map((family) => (
              <div
                key={family}
                className="ay-p-4 ay-hover:bg-gray-100 ay-cursor-pointer"
                onClick={() => handleFamilyChange(family)}
              >
                {family.toUpperCase()}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default FamilySelector;
