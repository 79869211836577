const products = [
  {
    name: "armchair",
    code: "ZX5MV597NN",
    hasFabric: true,
    hasWood: true,
    hasPiano: true,
  },
  {
    name: "bookshelf",
    code: "OOBQKI8610",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
  },

  {
    name: "NAAA0004_L390_H420",
    code: "A26IWWKPL2",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0007_L760_H420",
    code: "P47TLJP39M",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0010_L760_H420",
    code: "F7KIYBU05M",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0013_L1130_H420",
    code: "HBFEFHDDMU",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0016_L1130_H420",
    code: "A798GL0KSW",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0019_L390_H1040",
    code: "1WVMHP79CN",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0022_L390_H1040",
    code: "PYHEJJ6WOO",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0025_L760_H1040",
    code: "UMK87539WQ",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0028_L760_H1040",
    code: "2T49NBI2HA",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0031_L1130_H1040",
    code: "X5XHG9MEWU",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0034_L1130_H1040",
    code: "RTFGLS0F4V",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0037_L1500_H1040",
    code: "DVIXGCRLRC",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0040_L1500_H1040",
    code: "VN5I3EDCD1",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0043_L1870_H1040",
    code: "VN5I3EDCD1",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0046_L1870_H1040",
    code: "XHVKHIVRLX",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0049_L390_H1410",
    code: "L2K6E15FOQ",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0052_L390_H1410",
    code: "N3FQI691MD",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0055_L760_H1410",
    code: "M334IK8YDK",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0058_L760_H1410",
    code: "FHOBX3EKI0",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0061_L1130_H1410",
    code: "QCR4JB0V2W",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0064_L1130_H1410",
    code: "5V5T8YNAT9",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0067_L1500_H1410",
    code: "MCRDY2UOTA",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0070_L1500_H1410",
    code: "MXB7N129U7",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0073_L1870_H1410",
    code: "8UXDK89T3P",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0076_L1870_H1410",
    code: "DT3VLI7P1B",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0079_L390_H1780",
    code: "0QZQAMV56V",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0082_L390_H1780",
    code: "06H0EDAU7J",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0085_L760_H1780",
    code: "KPYVSEX994",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0088_L760_H1780",
    code: "R0FAZ043XA",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0091_L1130_H1780",
    code: "IFTQQQKL6Q",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0094_L1130_H1780",
    code: "JA0XCQEGUE",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0097_L1500_H1780",
    code: "8QXJLHYM0M",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0100_L1500_H1780",
    code: "2TQI0MSC9P",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0103_L1870_H1780",
    code: "IWW94E9TQY",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0106_L1870_H1780",
    code: "4ORRQH1I6X",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0109_L390_H2150",
    code: "B3WBRPP5BE",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0112_L390_H2150",
    code: "CSYZDAZK0B",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0115_L760_H2150",
    code: "DH53HHZWUS",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0118_L760_H2150",
    code: "YUDR5L4F5Z",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0121_L1130_H2150",
    code: "NMNPTEQRTZ",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0124_L1130_H2150",
    code: "W5QRNBXQ7N",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0127_L1500_H2150",
    code: "E0VM00SMXP",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0130_L1500_H2150",
    code: "E0VM00SMXP",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0133_L1870_H2150",
    code: "954E6PVNBW",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0136_L1870_H2150",
    code: "GWC909WJ9G",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0145_L1260_H420",
    code: "R2T28IVMJM",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0148_L1260_H420",
    code: "MBNYWO6OLU",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0151_L1880_H420",
    code: "MBNYWO6OLU",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0154_L1880_H420",
    code: "0UMY7EUN2S",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0157_L640_H1040",
    code: "CQAF0LNZ3E",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0160_L640_H1040",
    code: "5BZIV38S68",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0163_L1260_H1040",
    code: "UQRAD1VNCO",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0166_L1260_H1040",
    code: "LEV6XBBOH8",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0169_L1880_H1040",
    code: "LPW7NM1WSD",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0172_L1880_H1040",
    code: "WFFK4DCBBB",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0175_L640_H1410",
    code: "X7OFGXSL08",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0178_L640_H1410",
    code: "93AHKP4963",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0181_L1260_H1410",
    code: "FZXOLNH0RB",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0184_L1260_H1410",
    code: "ZVR0FR5K36",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0187_L1880_H1410",
    code: "PASKAHF85S",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0190_L1880_H1410",
    code: "L5IMVCIFU4",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0193_L640_H1780",
    code: "32BZVZC5IC",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0196_L640_H1780",
    code: "MJPBJ3CK4Q",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0199_L1260_H1780",
    code: "EKPCSAMVRC",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0202_L1260_H1780",
    code: "LZPUVCRGCU",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0205_L1880_H1780",
    code: "LZPUVCRGCU",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0208_L1880_H1780",
    code: "INAYT5595D",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0211_L640_H2150",
    code: "4ZL8D5Z3P0",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0214_L640_H2150",
    code: "1QGCYF0KWC",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0217_L1260_H2150",
    code: "UO6LI5VSQ1",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0220_L1260_H2150",
    code: "MA87CBWZUX",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0223_L1880_H2150",
    code: "AVIR6VIFC7",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0226_L1880_H2150",
    code: "9N0EQFBFVF",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0229_L840_H420",
    code: "KOZAUB0SMY",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0232_L840_H420",
    code: "YELJFS02QY",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0235_L1660_H420",
    code: "FZSOMRBGAC",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0238_L1660_H420",
    code: "FB0UD8C0G4",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0241_L840_H1040",
    code: "14B9ZC3SD4",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0244_L840_H1040",
    code: "YN5MNQP85P",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0247_L1660_H1040",
    code: "61APXRFNUJ",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0250_L1660_H1040",
    code: "BGF9W5BI38",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0253_L840_H1410",
    code: "P04UMJ6JZT",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0256_L840_H1410",
    code: "VWIJKDG2OQ",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0259_L1660_H1410",
    code: "MTBGJVJ7MT",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0262_L1660_H1410",
    code: "NCSMP0HJRI",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0265_L800_H1780",
    code: "HM5OYOX3UN",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0268_L800_H1780",
    code: "3PR1QXK8Q5",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0271_L1660_H1780",
    code: "WMW70VTUHH",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0274_L1660_H1780",
    code: "SL6TTKQFY9",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0277_L840_H2150",
    code: "BTA7XCCQ11",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0280_L840_H2150",
    code: "T03ABB1PW3",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0283_L1660_H2150",
    code: "B6BF82R4KQ",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0286_L1660_H2150",
    code: "19DOF5LIO8",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0291_L2460_H1040",
    code: "ERR2EJKYBJ",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0292_L2460_H1040",
    code: "44J8X01LX5",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0344_L1240_H1040",
    code: "THWCQ6QL6N",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0345_L1240_H1040",
    code: "ZUM64UBHSZ",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0346_L1240_H1410",
    code: "M68ZJ5NVWZ",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0347_L1240_H1410",
    code: "QTNCGMCLE4",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0348_L1240_H1780",
    code: "T0WJHHW7L4",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0349_L1240_H1780",
    code: "F4WBE041NJ",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0350_L1240_H2150",
    code: "W47SFQIZZI",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NAAA0351_L1240_H2150",
    code: "EG30QOSHF4",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "line",
  },
  {
    name: "NDAA0001 2100 2560 CENTRO STANZA",
    code: "Q7AHHV0ASM",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "Nia",
  },
  {
    name: "3AG01H010-AGRESTE H500 PIANO MELAMINE Ø600",
    code: "C5VSSD8QVJ",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customCollection: "line",
    family: "Ageste",
  },
];
export default products;
